import { PageType } from "../../../constants/types.constants";
import { PageEditBaseEntity } from "../../../entities/page-edit-base.entity";
import { EndPageDetailsEntity } from "./end-page-details.entity";

interface Params {
  id: number;
  imageName: string;
  description: string;
  type: PageType;
  imageUrl: string;
}

export class EndPageEditEntity extends PageEditBaseEntity {
  constructor(params: Params) {
    super({ ...params });
  }

  static fromDetailsEntity(entity: EndPageDetailsEntity) {
    return new EndPageEditEntity({
      ...entity,
    });
  }
}
