import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import DialogLayout from "../../../components/dialog-layout";
import DialogFormComponent from "../../../components/form/dialog-form.component";
import { FormItem, FormType } from "../../../components/form/form-generator";
import ImageUploader from "../../../components/image-uploader";
import VideoUploader from "../../../components/video-uploader";
import {
  FileUploadCategory,
  uploadFile,
  uploadVideoFile,
} from "../../../services/file-upload.service";
import {
  AlertType,
  useDashboardStore,
} from "../../dashboard/store/dashboard.store";
import { createQuizard } from "../apis/quizard.api";
import { QuizardCreateEntity } from "../entities/quizard-create.entity";
import { QuizardCreateModel } from "../models/quizard-create.model";

const formElements: FormItem[] = [
  {
    title: "Title",
    contentKey: "title",
    type: FormType.text,
  },
  {
    title: "Description",
    contentKey: "description",
    type: FormType.textArea,
  },
  {
    title: "Album Tag (kwizardoodle-)",
    contentKey: "albumTag",
    type: FormType.text,
  },
];

export default function AddQuizardDialog({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const setAlert = useDashboardStore((state) => state.setAlert);
  const [imageFile, setImageFile] = useState<File>();
  const [videoFile, setVideoFile] = useState<File>();

  const onSave = async (values: QuizardCreateEntity) => {
    if (imageFile) {
      values.imageName = await uploadFile(
        imageFile,
        FileUploadCategory.QUIZARD
      );
    }
    if (videoFile) {
      values.videoName = await uploadVideoFile(
        videoFile,
        FileUploadCategory.QUIZARD_VIDEO
      );
    }

    createQuizard(QuizardCreateModel.fromEntity(values))
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        setAlert({
          message: error.response.data.message,
          type: AlertType.error,
        });
      });
  };

  return (
    <DialogLayout
      title="Add Quizard"
      open={open}
      setOpen={setOpen}
      Icon={PlusCircleIcon}
    >
      <div className="flex flex-col mt-6 gap-y-4">
        <ImageUploader
          id="coverImage"
          label="Cover Image"
          setImageFile={setImageFile}
        />
        <VideoUploader
          id="introImage"
          label="Intro Video"
          setVideoFile={setVideoFile}
        />
        <DialogFormComponent
          elements={formElements}
          content={QuizardCreateEntity.createEmpty()}
          onClickSubmit={onSave}
          submitButtonTitle="Create"
          onClickCancel={() => setOpen(false)}
        />
      </div>
    </DialogLayout>
  );
}
