import Resizer from "react-image-file-resizer";
import { http } from "./http.service";

export enum FileUploadCategory {
  PAST = "past",
  FUTURE = "future",
  BIRTH = "birth",
  END = "end",
  QUIZARD = "quizard",
  QUIZARD_VIDEO = "quizard_video",
}

const resizeFile = (file: File, category: FileUploadCategory) => {
  return new Promise<any>((resolve) => {
    Resizer.imageFileResizer(
      file,
      1200,
      1200,
      "JPEG",
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64",
      1200,
      1200
    );
  });
};

export const uploadFile = async (
  uploadFile: File,
  category: FileUploadCategory
) => {
  if (uploadFile) {
    const sideLoadResponse = await http({
      url: `get-upload-url?type=${category}&format=jpg`,
      method: "GET",
      auth: true,
    });

    let uploadURL = sideLoadResponse.data.url;
    const newFileName = sideLoadResponse.data.fileName;
    let blob = await resizeFile(uploadFile, category);
    let binary = atob(blob.split(",")[1]);
    let array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    let blobData = new Blob([new Uint8Array(array)], { type: "image/jpeg" });
    await fetch(uploadURL, {
      method: "PUT",
      body: blobData,
    });

    return newFileName;
  }
};

const toBase64 = (file: File) =>
  new Promise<any>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const uploadVideoFile = async (
  uploadFile: File,
  category: FileUploadCategory
) => {
  if (uploadFile) {
    const sideLoadResponse = await http({
      url: `get-upload-url?type=${category}&format=mp4`,
      method: "GET",
      auth: true,
    });

    let uploadURL = sideLoadResponse.data.url;
    const newFileName = sideLoadResponse.data.fileName;
    let blob = await toBase64(uploadFile);
    let binary = atob(blob.split(",")[1]);
    let array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    let blobData = new Blob([new Uint8Array(array)], { type: "video/mp4" });
    await fetch(uploadURL, {
      method: "PUT",
      body: blobData,
    });

    return newFileName;
  }
};
