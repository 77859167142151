import { ReactNode } from "react";
import CardComponent from "./card-component";

export default function InfoCardComponent({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) {
  return (
    <CardComponent>
      <div className="flex flex-col w-full">
        <h1 className="truncate text-gray-500 text-center">{title}</h1>
        {children}
      </div>
    </CardComponent>
  );
}
