import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../../modules/auth/store/auth.store";
import { meApi } from "../../modules/current-user/apis/current-user.api";
import { CurrentUserModel } from "../../modules/current-user/models/current-user.model";
import { useUserStore } from "../../modules/current-user/store/current-user.store";
import {
  AlertType,
  useDashboardStore,
} from "../../modules/dashboard/store/dashboard.store";
import { classNames } from "../../utils/tailwind-utils";

export default function ProfileButton() {
  const initEffect = useRef<boolean>(false);
  const navigate = useNavigate();
  const logout = useAuthStore((state) => state.logout);
  const setAlert = useDashboardStore((state) => state.setAlert);

  const setUser = useUserStore((state) => state.setUser);
  const user = useUserStore((state) => state.user);

  const onLogout = () => {
    logout();
    navigate("/login");
  };

  const userNavigation = [
    { name: "Your profile", onClick: () => {} },
    { name: "Sign out", onClick: onLogout },
  ];

  useEffect(() => {
    if (initEffect.current === false) {
      if (!user) {
        meApi()
          .then((res) => {
            const userData = res.data;
            setUser(new CurrentUserModel(userData).toEntity());
          })
          .catch((error) => {
            setAlert({
              message: error.response.data.message,
              type: AlertType.error,
            });
          });
      }
      return () => {
        initEffect.current = true;
      };
    }
  }, [setUser, user, setAlert]);

  return (
    <>
      {/* Profile dropdown */}
      <Menu as="div" className="relative">
        <Menu.Button className="-m-1.5 flex items-center p-1.5">
          <span className="sr-only">Open user menu</span>
          <img
            className="h-8 w-8 rounded-full bg-gray-50"
            src="https://vidavu-assests-stage.s3.amazonaws.com/assets/profile-image-default.jpg"
            alt=""
          />
          <span className="hidden lg:flex lg:items-center">
            <span
              className="ml-4 text-sm font-semibold leading-6 text-gray-900"
              aria-hidden="true"
            >
              {user?.firstName} {user?.lastName}
            </span>
            <ChevronDownIcon
              className="ml-2 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
            {userNavigation.map((item) => (
              <Menu.Item key={item.name}>
                {({ active }) => (
                  <button
                    onClick={item.onClick}
                    className={classNames(
                      active ? "bg-gray-50" : "",
                      "block px-3 py-1 text-sm leading-6 text-gray-900"
                    )}
                  >
                    {item.name}
                  </button>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
}
