import { PageType } from "../../../constants/types.constants";
import { PastPageCreateEntity } from "../entities/past-page-create.entity";

interface Params {
  pageYear: number;
  dateStamp: string | null;
  imageName: string;
  headLine: string;
  description: string;
  type: PageType;
}

export class PastPageCreateModel {
  pageYear: number;
  dateStamp: string | null;
  imageName: string;
  headLine: string;
  description: string;
  type: PageType;

  constructor(params: Params) {
    this.pageYear = params.pageYear;
    this.dateStamp = params.dateStamp;
    this.imageName = params.imageName;
    this.headLine = params.headLine;
    this.description = params.description;
    this.type = params.type;
  }

  static fromEntity = (entity: PastPageCreateEntity) =>
    new PastPageCreateModel({
      pageYear: parseInt(entity.pageYear),
      dateStamp: entity.dateStamp === "" ? null : entity.dateStamp,
      imageName: entity.imageName,
      headLine: entity.headLine,
      description: entity.description,
      type: entity.type,
    });
}
