import { http } from "../../../services/http.service";
import { QuizardCreateModel } from "../models/quizard-create.model";
import { QuizardEditModel } from "../models/quizard-edit.model";

export const getQuizards = (
  paginatedPageId: string,
  searchString: string | null
) =>
  http({
    url: "quizard",
    method: "GET",
    auth: true,
    params: {
      paginatedPageId,
      paginatedPageLimit: 20,
      searchString,
    },
  });

export const createQuizard = (data: QuizardCreateModel) =>
  http({
    url: "quizard",
    method: "POST",
    auth: true,
    data,
  });

export const getQuizardById = (id: string) =>
  http({
    url: "quizard/getById",
    method: "GET",
    auth: true,
    params: {
      id,
    },
  });

export const updateQuizard = (data: QuizardEditModel) =>
  http({
    url: "quizard",
    method: "PUT",
    auth: true,
    data,
  });

export const deleteQuizard = (id: number) =>
  http({
    url: "quizard",
    method: "DELETE",
    auth: true,
    params: {
      id,
    },
  });
