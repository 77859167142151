import { QuizardEntity } from "../entities/quizard.entity";

export interface IQuizardModel {
  id: number;
  title: string;
  description: string;
  imageName: string;
  type: string;
  createdAt: string;
}

export class QuizardModel {
  id: number;
  title: string;
  description: string;
  imageName: string;
  type: string;
  createdAt: string;

  constructor(data: IQuizardModel) {
    this.id = data.id;
    this.title = data.title;
    this.description = data.description;
    this.imageName = data.imageName;
    this.type = data.type;
    this.createdAt = data.createdAt;
  }

  toEntity(): QuizardEntity {
    return {
      id: this.id,
      title: this.title,
      description: this.description,
      imageName: this.imageName,
      type: this.type,
      createdAt: this.createdAt,
    };
  }
}
