import { PageType } from "../../../constants/types.constants";
import { PastPageEditEntity } from "../entities/past-page-edit.entity";

interface Params {
  id: number;
  pageYear: number;
  dateStamp: string | null;
  headLine: string;
  imageName: string;
  description: string;
  type: PageType;
}

export class PastPageEditModel {
  id: number;
  pageYear: number;
  dateStamp: string | null;
  headLine: string;
  imageName: string;
  description: string;
  type: PageType;

  constructor(params: Params) {
    this.id = params.id;
    this.pageYear = params.pageYear;
    this.dateStamp = params.dateStamp;
    this.headLine = params.headLine;
    this.imageName = params.imageName;
    this.description = params.description;
    this.type = params.type;
  }

  static fromEntity(entity: PastPageEditEntity) {
    return new PastPageEditModel({
      ...entity,
      pageYear: parseInt(entity.pageYear),
      dateStamp: entity.dateStamp === "" ? null : entity.dateStamp,
    });
  }
}
