import { PageType } from "../../../constants/types.constants";
import { EndPageEntity } from "../entities/end-page.entity";

export interface Params {
  id: number;
  imageName: string;
  description: string;
  type: PageType;
  createdAt: string;
  updatedAt: string;
}

export class EndPageModel {
  id: number;
  imageName: string;
  description: string;
  type: PageType;
  createdAt: string;
  updatedAt: string;

  constructor(params: Params) {
    this.id = params.id;
    this.imageName = params.imageName;
    this.description = params.description;
    this.type = params.type;
    this.createdAt = params.createdAt;
    this.updatedAt = params.updatedAt;
  }

  toEntity(): EndPageEntity {
    return {
      id: this.id,
      imageName: this.imageName,
      description: this.description,
      type: this.type,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }
}
