import { PageType } from "../../../constants/types.constants";
import { PageCreateBaseEntity } from "../../../entities/page-create-base.entity";

interface Params {
  imageName: string;
  description: string;
}

export class EndPageCreateEntity extends PageCreateBaseEntity {
  constructor(params: Params) {
    super({ ...params, type: PageType.end });
  }

  static createEmpty = (): EndPageCreateEntity => {
    return new EndPageCreateEntity({
      imageName: "",
      description: "",
    });
  };
}
