import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ErrorMessageComponent from "../../../components/error-message.component";
import { FormItem, FormType } from "../../../components/form/form-generator";
import PageFormComponent from "../../../components/form/page-form.component";
import { resetPasswordApi } from "../apis/auth.api";
import { UserResetPasswordEntity } from "../entities/user-reset-password.entity";
import { UserResetPasswordModel } from "../models/user-reset-password.model";
import { useAuthStore } from "../store/auth.store";

const elements: FormItem[] = [
  {
    title: "New Password",
    contentKey: "newPassword",
    type: FormType.password,
  },
  {
    title: "Confirm Password",
    contentKey: "confirmPassword",
    type: FormType.password,
  },
];

export default function ResetPasswordPage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const login = useAuthStore((state) => state.login);

  const handleSubmit = (values: UserResetPasswordEntity) => {
    resetPasswordApi(UserResetPasswordModel.fromEntity(values))
      .then((res) => {
        setErrorMessage(null);
        login(res.data.token);
        navigate("/login");
      })
      .catch((e) => {
        setErrorMessage(e.response.data.message);
      });
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-20 w-auto"
            src="https://vidavu-assests-stage.s3.amazonaws.com/assets/logo_image.png"
            alt="Logo"
          />
          <h2 className="mt-4 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Vidavu Admin Password Reset
          </h2>

          <p className="mt-2 text-center text-xl leading-9 tracking-tight text-gray-900">
            {searchParams.get("email") ?? ""}
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-sm">
          {errorMessage && (
            <div className="py-2">
              <ErrorMessageComponent message={errorMessage} />
            </div>
          )}
          <PageFormComponent
            elements={elements}
            content={UserResetPasswordEntity.createEmpty(
              searchParams.get("email") ?? "",
              searchParams.get("token") ?? ""
            )}
            onClickSubmit={handleSubmit}
            submitButtonTitle="Reset Password"
          />
        </div>
      </div>
    </>
  );
}
