interface IUserResetPasswordEntity {
  email: string;
  token: string;
  newPassword: string;
  confirmPassword: string;
}

export class UserResetPasswordEntity {
  email: string;
  token: string;
  newPassword: string;
  confirmPassword: string;

  constructor(params: IUserResetPasswordEntity) {
    this.email = params.email;
    this.token = params.token;
    this.newPassword = params.newPassword;
    this.confirmPassword = params.confirmPassword;
  }

  static createEmpty = (
    email: string,
    token: string
  ): UserResetPasswordEntity => {
    return new UserResetPasswordEntity({
      email,
      token,
      newPassword: "",
      confirmPassword: "",
    });
  };
}
