import { AdminEntity } from "../entities/admin.entity";

export interface IAdminModel {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}

export class AdminModel {
  id: number;
  firstName: string;
  lastName: string;
  email: string;

  constructor(data: IAdminModel) {
    this.id = data.id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
  }

  toEntity(): AdminEntity {
    return {
      id: this.id,
      name: `${this.firstName} ${this.lastName}`,
      email: this.email,
      roles: "ADMIN",
    };
  }
}
