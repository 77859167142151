import { useState } from "react";

export interface FormFunctions<T> {
  onChange: any;
  onCheck: any;
  onSubmit: any;
  onClear: any;
  onCustomChange: any;
  values: T;
  onLoad: any;
  changed: boolean;
}

// useForm functional component
export const useForm = <T>(callback: any, initialState: T) => {
  const [values, setValues] = useState(initialState);
  const [changed, setChanged] = useState(false);

  // onChange
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChanged(true);
    setValues((values: T) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const onCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChanged(true);
    setValues((values: T) => ({
      ...values,
      [event.target.name]: event.target.checked,
    }));
  };

  const onClear = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChanged(false);
    setValues(initialState);
  };

  const onCustomChange = (name: string, value: any) => {
    setChanged(true);
    setValues((values: T) => ({ ...values, [name]: value }));
  };

  const onLoad = (loadedValues: T) => {
    setValues(loadedValues);
  };

  // onSubmit
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await callback(); // triggering the callback
    setChanged(false);
  };

  // return values
  return {
    onChange,
    onCheck,
    onSubmit,
    onClear,
    onCustomChange,
    values,
    onLoad,
    changed,
  };
};
