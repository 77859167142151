interface Params {
  title: string;
  description: string;
  imageName: string;
  videoName: string;
  albumTag: string;
}

export class QuizardCreateEntity {
  title: string;
  description: string;
  imageName: string;
  videoName: string;
  albumTag: string;

  constructor(params: Params) {
    this.title = params.title;
    this.description = params.description;
    this.imageName = params.imageName;
    this.albumTag = params.albumTag;
    this.videoName = params.videoName;
  }

  static createEmpty = (): QuizardCreateEntity => {
    return new QuizardCreateEntity({
      title: "",
      description: "",
      imageName: "",
      videoName: "",
      albumTag: "",
    });
  };
}
