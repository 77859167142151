interface IAdminDetailsEntity {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  createdAt: string;
}

export class AdminDetailsEntity {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  createdAt: string;

  constructor(params: IAdminDetailsEntity) {
    this.id = params.id;
    this.firstName = params.firstName;
    this.lastName = params.lastName;
    this.email = params.email;
    this.createdAt = params.createdAt;
  }

  static createEmpty = (): AdminDetailsEntity => {
    return new AdminDetailsEntity({
      id: -1,
      firstName: "",
      lastName: "",
      email: "",
      createdAt: "",
    });
  };
}
