import { UserResetPasswordEntity } from "../entities/user-reset-password.entity";

export interface IUserResetPasswordModel {
  email: string;
  token: string;
  newPassword: string;
  confirmPassword: string;
}

export class UserResetPasswordModel {
  email: string;
  token: string;
  newPassword: string;
  confirmPassword: string;

  constructor(data: IUserResetPasswordModel) {
    this.email = data.email;
    this.token = data.token;
    this.newPassword = data.newPassword;
    this.confirmPassword = data.confirmPassword;
  }

  static fromEntity(entity: UserResetPasswordEntity) {
    return new UserResetPasswordModel({
      email: entity.email,
      token: entity.token,
      newPassword: entity.newPassword,
      confirmPassword: entity.confirmPassword,
    });
  }
}
