import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

export interface PaginationData {
  currentPage: number;
  total: number;
  totalPages: number;
  perPage: number;
}

export default function Pagination({
  data,
  onClick,
}: {
  data: PaginationData;
  onClick: (page: number) => void;
}) {
  const firstValue = (data.currentPage - 1) * data.perPage + 1;
  const secondValue =
    data.currentPage * data.perPage < data.total
      ? data.currentPage * data.perPage
      : data.total;

  const noPagination = data.totalPages === 1;

  const generateNoSpanArray = () => {
    return (
      <>
        {Array.from(Array(data.totalPages).keys()).map((x) => {
          const page = x + 1;
          if (page === data.currentPage) {
            return (
              <button
                key={page}
                aria-current="page"
                className="relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={() => onClick(page)}
              >
                {page}
              </button>
            );
          } else {
            return (
              <button
                key={page}
                className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                onClick={() => onClick(page)}
              >
                {page}
              </button>
            );
          }
        })}
      </>
    );
  };

  const generateEndSpanArray = () => {
    const pageList = Array.from(Array(7).keys()).map((x) => {
      const page = x + 1;
      if (page === data.currentPage) {
        return (
          <button
            key={page}
            aria-current="page"
            className="relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => onClick(page)}
          >
            {page}
          </button>
        );
      } else {
        return (
          <button
            key={page}
            className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            onClick={() => onClick(page)}
          >
            {page}
          </button>
        );
      }
    });
    pageList.push(
      <span
        key="span"
        className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0"
      >
        ...
      </span>
    );
    pageList.push(
      <button
        key={data.totalPages}
        className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        onClick={() => onClick(data.totalPages)}
      >
        {data.totalPages}
      </button>
    );
    return <>{pageList}</>;
  };

  const generateStartSpanArray = () => {
    const pageList = [];
    pageList.push(
      <button
        key={1}
        className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        onClick={() => onClick(1)}
      >
        1
      </button>
    );
    pageList.push(
      <span
        key="span"
        className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0"
      >
        ...
      </span>
    );

    for (let page = data.totalPages - 6; page <= data.totalPages; page++) {
      if (page === data.currentPage) {
        pageList.push(
          <button
            key={page}
            aria-current="page"
            className="relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => onClick(page)}
          >
            {page}
          </button>
        );
      } else {
        pageList.push(
          <button
            key={page}
            className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            onClick={() => onClick(page)}
          >
            {page}
          </button>
        );
      }
    }

    return <>{pageList}</>;
  };

  const generateBothSpanArray = () => {
    const pageList = [];
    pageList.push(
      <button
        key={1}
        className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        onClick={() => onClick(1)}
      >
        1
      </button>
    );
    pageList.push(
      <span
        key="span"
        className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0"
      >
        ...
      </span>
    );

    for (
      let page = data.currentPage - 2;
      page <= data.currentPage + 2;
      page++
    ) {
      if (page === data.currentPage) {
        pageList.push(
          <button
            key={page}
            aria-current="page"
            className="relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => onClick(page)}
          >
            {page}
          </button>
        );
      } else {
        pageList.push(
          <button
            key={page}
            className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            onClick={() => onClick(page)}
          >
            {page}
          </button>
        );
      }
    }

    pageList.push(
      <span
        key="span2"
        className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0"
      >
        ...
      </span>
    );
    pageList.push(
      <button
        key={data.totalPages}
        className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        onClick={() => onClick(data.totalPages)}
      >
        {data.totalPages}
      </button>
    );

    return <>{pageList}</>;
  };

  const generateButtons = () => {
    if (noPagination) {
      return <></>;
    } else if (data.totalPages < 9) {
      return generateNoSpanArray();
    } else if (data.currentPage < 6) {
      return generateEndSpanArray();
    } else if (data.currentPage > data.totalPages - 5) {
      return generateStartSpanArray();
    } else {
      return generateBothSpanArray();
    }
  };

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{firstValue}</span> to{" "}
            <span className="font-medium">{secondValue}</span> of{" "}
            <span className="font-medium">{data.total}</span> results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            {!noPagination && (
              <button
                className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                onClick={() => onClick(data.currentPage - 1)}
                disabled={data.currentPage === 1}
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            )}
            {generateButtons()}
            {!noPagination && (
              <button
                className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                onClick={() => onClick(data.currentPage + 1)}
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
}
