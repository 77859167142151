import { QuizardEditEntity } from "../entities/quizard-edit.entity";

interface Params {
  id: number;
  title: string;
  description: string;
  imageName: string;
  videoName: string;
  type: string;
}

export class QuizardEditModel {
  id: number;
  title: string;
  description: string;
  imageName: string;
  videoName: string;
  type: string;

  constructor(params: Params) {
    this.id = params.id;
    this.title = params.title;
    this.description = params.description;
    this.imageName = params.imageName;
    this.videoName = params.videoName;
    this.type = params.type;
  }

  static fromEntity = (entity: QuizardEditEntity) =>
    new QuizardEditModel({
      ...entity,
    });
}
