import CardComponent from "./card-component";

export interface StatCardItem {
  id: number;
  icon: any;
  name: string;
  stat: string;
}
export default function StatCardComponent({ item }: { item: StatCardItem }) {
  return (
    <CardComponent>
      <div className="rounded-md bg-indigo-500 p-3">
        <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
      </div>
      <div className="p-4">
        <dt>
          <p className="truncate text-sm font-medium text-gray-500">
            {item.name}
          </p>
        </dt>
        <dd className="flex items-baseline ">
          <p className="text-4xl font-semibold text-gray-900">{item.stat}</p>
        </dd>
      </div>
    </CardComponent>
  );
}
