import { Dispatch, SetStateAction, useState } from "react";
import FormElement from "./form/form-element";

export interface ImageUploadParams {
  id: string;
  label: string;
  previewUrl?: string;
  setImageFile: Dispatch<SetStateAction<File | undefined>>;
  className?: string;
}

export default function ImageUploader(params: ImageUploadParams) {
  const [preview, setPreview] = useState(
    params.previewUrl ??
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
  );

  const onChange = (e: any) => {
    createImage(e.target.files[0]);
  };

  const createImage = (file: any) => {
    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);
      params.setImageFile(file);
    }
  };

  return (
    <FormElement
      id={params.id}
      label={params.label}
      className={params.className}
    >
      <div className="col-span-full flex items-center gap-x-8">
        <img
          src={preview}
          alt="Uploader"
          className="h-60 w-60 flex-none rounded-lg bg-gray-800 object-cover"
        />

        <div>
          <label className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            <span>Change image</span>
            <input
              className="hidden"
              type="file"
              accept=".jpg, .png, .jpeg |image/*"
              onChange={onChange}
            />
          </label>
          <p className="mt-2 text-xs leading-5 text-gray-400">
            JPG, JPEG or PNG
          </p>
        </div>
      </div>
    </FormElement>
  );
}
