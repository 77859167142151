import FormElement from "./form-element";
import Input, { FormParams } from "./input";

export default function PasswordInput(params: FormParams) {
  return (
    <FormElement
      id={params.id}
      label={params.label}
      className={params.className}
    >
      <Input type="password" autoComplete="current-password" {...params} />
    </FormElement>
  );
}
