import AddPageDialog from "../../../components/add-page.dialog";
import { FormItem, FormType } from "../../../components/form/form-generator";
import { FileUploadCategory } from "../../../services/file-upload.service";
import {
  AlertType,
  useDashboardStore,
} from "../../dashboard/store/dashboard.store";
import { createPastPage } from "../apis/past-page.api";
import { PastPageCreateEntity } from "../entities/past-page-create.entity";
import { PastPageCreateModel } from "../models/past-page-create.model";

const formElements: FormItem[] = [
  {
    title: "Year",
    contentKey: "pageYear",
    type: FormType.number,
  },
  {
    title: "Date",
    contentKey: "dateStamp",
    type: FormType.date,
  },
  {
    title: "Head Line",
    contentKey: "headLine",
    type: FormType.text,
  },
  {
    title: "Description",
    contentKey: "description",
    type: FormType.textArea,
  },
];

export default function AddPastPageDialog({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const setAlert = useDashboardStore((state) => state.setAlert);
  const onSave = (values: PastPageCreateEntity) => {
    createPastPage(PastPageCreateModel.fromEntity(values))
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        setAlert({
          message: error.response.data.message,
          type: AlertType.error,
        });
      });
  };

  return (
    <AddPageDialog
      title="Add Past Page"
      open={open}
      setOpen={setOpen}
      page={PastPageCreateEntity.createEmpty()}
      formElements={formElements}
      onSaveClick={onSave}
      uploadCategory={FileUploadCategory.PAST}
    />
  );
}
