import { AdminDetailsEntity } from "../entities/admin-details.entity";

export interface IAdminCreateModel {
  firstName: string;
  lastName: string;
  email: string;
  roles: string[];
}

export class AdminCreateModel {
  firstName: string;
  lastName: string;
  email: string;
  roles: string[];

  constructor(data: IAdminCreateModel) {
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.roles = data.roles;
  }

  static fromEntity = (entity: AdminDetailsEntity) => {
    return new AdminCreateModel({
      firstName: entity.firstName,
      lastName: entity.lastName,
      email: entity.email,
      roles: ["SUPER_ADMIN"],
    });
  };
}
