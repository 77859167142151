import AddPageDialog from "../../../components/add-page.dialog";
import { FormItem, FormType } from "../../../components/form/form-generator";
import { FileUploadCategory } from "../../../services/file-upload.service";
import {
  AlertType,
  useDashboardStore,
} from "../../dashboard/store/dashboard.store";
import { createFuturePage } from "../apis/future-page.api";
import { FuturePageCreateEntity } from "../entities/future-page-create.entity";
import { FuturePageCreateModel } from "../models/future-page-create.model";

const formElements: FormItem[] = [
  {
    title: "Year",
    contentKey: "pageYear",
    type: FormType.number,
  },
  {
    title: "Description",
    contentKey: "description",
    type: FormType.textArea,
  },
];

export default function AddFuturePageDialog({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const setAlert = useDashboardStore((state) => state.setAlert);
  const onSave = async (values: any) => {
    createFuturePage(FuturePageCreateModel.fromEntity(values))
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        setAlert({
          message: error.response.data.message,
          type: AlertType.error,
        });
      });
  };

  return (
    <AddPageDialog
      title="Add Future Page"
      open={open}
      setOpen={setOpen}
      page={FuturePageCreateEntity.createEmpty()}
      formElements={formElements}
      onSaveClick={onSave}
      uploadCategory={FileUploadCategory.FUTURE}
    />
  );
}
