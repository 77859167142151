import { PageType } from "../../../constants/types.constants";
import { FuturePageCreateEntity } from "../entities/future-page-create.entity";

interface Params {
  pageYear: number;
  imageName: string;
  description: string;
  type: PageType;
}

export class FuturePageCreateModel {
  pageYear: number;
  imageName: string;
  description: string;
  type: PageType;

  constructor(params: Params) {
    this.pageYear = params.pageYear;
    this.imageName = params.imageName;
    this.description = params.description;
    this.type = params.type;
  }

  static fromEntity = (entity: FuturePageCreateEntity) =>
    new FuturePageCreateModel({
      pageYear: parseInt(entity.pageYear),
      imageName: entity.imageName,
      description: entity.description,
      type: entity.type,
    });
}
