import { http } from "../../../services/http.service";
import { AdminCreateModel } from "../models/admin-create.model";
import { AdminUpdateModel } from "../models/admin-update.model";

export const getAdmins = (
  paginatedPageId: string,
  searchString: string | null
) =>
  http({
    url: "super-users",
    method: "GET",
    auth: true,
    params: {
      paginatedPageId,
      paginatedPageLimit: 10,
      searchString,
    },
  });

export const getAdminById = (id: string) =>
  http({
    url: "super-users/getById",
    method: "GET",
    auth: true,
    params: {
      id,
    },
  });

export const updateAdmin = (data: AdminUpdateModel) =>
  http({ url: "super-users", method: "PUT", auth: true, data });
export const createAdmin = (data: AdminCreateModel) =>
  http({ url: "super-users", method: "POST", auth: true, data });
export const deleteAdmin = (id: number) =>
  http({
    url: "super-users",
    method: "DELETE",
    auth: true,
    params: {
      id,
    },
  });
