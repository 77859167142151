import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { BREADCRUMBS, PATHS } from "../../constants/path.constants";

export interface Breadcrumbs {
  path: string;
  breadcrumbList: BreadcrumbItem[];
}

interface BreadcrumbItem {
  name: string;
  href: string;
}

export default function Breadcrumb() {
  const breadcrumbList = useMemo(() => {
    return BREADCRUMBS.find(
      (breadcrumb) => breadcrumb.path === window.location.pathname
    )?.breadcrumbList;
  }, []);

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div>
            <Link
              to={PATHS.home}
              className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
            >
              Dashboard
            </Link>
          </div>
        </li>
        {breadcrumbList &&
          breadcrumbList.map((item) => (
            <li key={item.name}>
              <div className="flex items-center">
                <ChevronRightIcon
                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                {item.href ? (
                  <Link
                    to={item.href}
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  >
                    {item.name}
                  </Link>
                ) : (
                  <div className="ml-4 text-sm font-medium text-gray-500">
                    {item.name}
                  </div>
                )}
              </div>
            </li>
          ))}
      </ol>
    </nav>
  );
}
