import { create } from "zustand";

export enum AlertType {
  success,
  warning,
  error,
}

export interface AlertMessage {
  message: string;
  type: AlertType;
}

interface DashboardState {
  alert: AlertMessage | null;
  setAlert: (alert: AlertMessage) => void;
  clearAlert: () => void;
}

export const useDashboardStore = create<DashboardState>((set, get) => ({
  alert: null,
  setAlert: (alert: AlertMessage) => {
    setTimeout(() => {
      set({ alert: null });
    }, 10000);
    set({ alert: alert });
  },
  clearAlert: () => {
    set({ alert: null });
  },
}));
