import DateInput from "./date-input";
import EmailInput from "./email-input";
import NumberInput from "./number-input";
import PasswordInput from "./password-input";
import Select, { Option } from "./select";
import TextArea from "./text-area";
import TextInput from "./text-input";

export enum FormType {
  text,
  number,
  date,
  email,
  password,
  textArea,
  dropdown,
}

export interface FormItem {
  title: string;
  contentKey: string;
  type: FormType;
  options?: Option[];
}

export default function FormGenerator({
  elements,
  values,
  onChange,
}: {
  elements: FormItem[];
  values: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <>
      {elements.map((element: FormItem) => {
        return (
          <div key={element.contentKey} className="mt-3">
            {element.type === FormType.text ? (
              <TextInput
                id={element.contentKey}
                label={element.title}
                value={values[element.contentKey]}
                onChange={onChange}
              />
            ) : element.type === FormType.number ? (
              <NumberInput
                id={element.contentKey}
                label={element.title}
                value={values[element.contentKey]}
                onChange={onChange}
              />
            ) : element.type === FormType.email ? (
              <EmailInput
                id={element.contentKey}
                label={element.title}
                value={values[element.contentKey]}
                onChange={onChange}
              />
            ) : element.type === FormType.password ? (
              <PasswordInput
                id={element.contentKey}
                label={element.title}
                value={values[element.contentKey]}
                onChange={onChange}
              />
            ) : element.type === FormType.textArea ? (
              <TextArea
                id={element.contentKey}
                label={element.title}
                value={values[element.contentKey]}
                onChange={onChange}
              />
            ) : element.type === FormType.date ? (
              <DateInput
                id={element.contentKey}
                label={element.title}
                value={values[element.contentKey]}
                onChange={onChange}
              />
            ) : element.type === FormType.dropdown ? (
              <Select
                id={element.contentKey}
                label={element.title}
                value={values[element.contentKey]}
                onChange={onChange}
                options={element.options ?? []}
              />
            ) : (
              <>Unknown type</>
            )}
          </div>
        );
      })}
    </>
  );
}
