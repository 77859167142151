import { PageType } from "../../../constants/types.constants";
import { http } from "../../../services/http.service";
import { PastPageCreateModel } from "../models/past-page-create.model";
import { PastPageEditModel } from "../models/past-page-edit.model";

export const getPastPages = (
  paginatedPageId: string,
  searchString: string | null
) =>
  http({
    url: "default-pages",
    method: "GET",
    auth: true,
    params: {
      paginatedPageId,
      paginatedPageLimit: 20,
      type: PageType.past,
      searchString,
    },
  });

export const getPastPageById = (id: string) =>
  http({
    url: "default-pages/getById",
    method: "GET",
    auth: true,
    params: {
      id,
    },
  });

export const createPastPage = (data: PastPageCreateModel) =>
  http({
    url: "default-pages",
    method: "POST",
    auth: true,
    data,
  });

export const updatePastPage = (data: PastPageEditModel) =>
  http({
    url: "default-pages",
    method: "PUT",
    auth: true,
    data,
  });

export const deletePastPage = (id: number) =>
  http({
    url: "default-pages",
    method: "DELETE",
    auth: true,
    params: {
      id,
    },
  });
