export default function FormElement({
  children,
  id,
  label,
  className,
}: {
  children: any;
  id: string;
  label?: string;
  className?: string;
}) {
  return (
    <div className={`text-start ${className}`}>
      {label && (
        <label htmlFor={id} className="text-sm font-medium text-gray-900">
          {label}
        </label>
      )}
      <div className={label && "mt-2"}>{children}</div>
    </div>
  );
}
