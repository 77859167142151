import { PageType } from "../../../constants/types.constants";
import { http } from "../../../services/http.service";
import { BirthPageCreateModel } from "../models/birth-page-create.model";
import { BirthPageEditModel } from "../models/birth-page-edit.model";

export const getBirthPages = (
  paginatedPageId: string,
  searchString: string | null
) =>
  http({
    url: "default-pages",
    method: "GET",
    auth: true,
    params: {
      paginatedPageId,
      paginatedPageLimit: 20,
      type: PageType.birth,
      searchString,
    },
  });

export const getBirthPageById = (id: string) =>
  http({
    url: "default-pages/getById",
    method: "GET",
    auth: true,
    params: {
      id,
    },
  });

export const createBirthPage = (data: BirthPageCreateModel) =>
  http({
    url: "default-pages",
    method: "POST",
    auth: true,
    data,
  });

export const updateBirthPage = (data: BirthPageEditModel) =>
  http({
    url: "default-pages",
    method: "PUT",
    auth: true,
    data,
  });

export const deleteBirthPage = (id: number) =>
  http({
    url: "default-pages",
    method: "DELETE",
    auth: true,
    params: {
      id,
    },
  });
