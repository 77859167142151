import { PageType } from "../../../constants/types.constants";
import { EndPageEditEntity } from "../entities/end-page-edit.entity";

interface Params {
  id: number;
  imageName: string;
  description: string;
  type: PageType;
}

export class EndPageEditModel {
  id: number;
  imageName: string;
  description: string;
  type: PageType;

  constructor(params: Params) {
    this.id = params.id;
    this.imageName = params.imageName;
    this.description = params.description;
    this.type = params.type;
  }

  static fromEntity(entity: EndPageEditEntity) {
    return new EndPageEditModel({
      ...entity,
    });
  }
}
