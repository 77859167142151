import { http } from "../../../services/http.service";

export const getUsers = (
  paginatedPageId: string,
  searchString: string | null
) =>
  http({
    url: "users",
    method: "GET",
    auth: true,
    params: {
      paginatedPageId,
      paginatedPageLimit: 10,
      searchString,
    },
  });

export const getUsersById = (id: string) =>
  http({
    url: "users/getById",
    method: "GET",
    auth: true,
    params: {
      id,
    },
  });
