import { CurrentUserEntity } from "../entities/current-user.entity";

interface ICurrentUserModel {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  profilePicture: string;
}

export class CurrentUserModel {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  profilePicture: string;

  constructor(user: ICurrentUserModel) {
    this.id = user.id;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.email = user.email;
    this.profilePicture =
      user.profilePicture === ""
        ? "https://vidavu-assests-stage.s3.amazonaws.com/assets/profile-image-default.jpg"
        : user.profilePicture;
  }

  toEntity(): CurrentUserEntity {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      profilePicture: this.profilePicture,
    };
  }
}
