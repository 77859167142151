import { PageType } from "../../../constants/types.constants";
import { http } from "../../../services/http.service";
import { FuturePageCreateModel } from "../models/future-page-create.model";
import { FuturePageEditModel } from "../models/future-page-edit.model";

export const getFuturePages = (
  paginatedPageId: string,
  searchString: string | null
) =>
  http({
    url: "default-pages",
    method: "GET",
    auth: true,
    params: {
      paginatedPageId,
      paginatedPageLimit: 20,
      type: PageType.future,
      searchString,
    },
  });

export const getFuturePageById = (id: string) =>
  http({
    url: "default-pages/getById",
    method: "GET",
    auth: true,
    params: {
      id,
    },
  });

export const createFuturePage = (data: FuturePageCreateModel) =>
  http({
    url: "default-pages",
    method: "POST",
    auth: true,
    data,
  });

export const updateFuturePage = (data: FuturePageEditModel) =>
  http({
    url: "default-pages",
    method: "PUT",
    auth: true,
    data,
  });

export const deleteFuturePage = (id: number) =>
  http({
    url: "default-pages",
    method: "DELETE",
    auth: true,
    params: {
      id,
    },
  });
