import { UserDetailsEntity } from "../entities/user-details.entity";

export interface IUserDetailsModel {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  updatedAt: string;
  birthYear: string;
  birthDay?: string;
  profilePhoto: string;
  emailVerifiedAt: string;
  createdAt: string;
}

export class UserDetailsModel {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  updatedAt: string;
  birthYear: string;
  birthDay?: string;
  profilePhoto: string;
  emailVerifiedAt: string;
  createdAt: string;

  constructor(data: IUserDetailsModel) {
    this.id = data.id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.updatedAt = data.updatedAt;
    this.birthYear = data.birthYear;
    this.birthDay = data.birthDay;
    this.profilePhoto = data.profilePhoto;
    this.emailVerifiedAt = data.emailVerifiedAt;
    this.createdAt = data.createdAt;
  }

  toEntity(): UserDetailsEntity {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      updatedAt: this.updatedAt,
      birthYear: this.birthYear,
      birthDay: this.birthDay,
      emailVerifiedAt: this.emailVerifiedAt,
      createdAt: this.createdAt,
    };
  }
}
