import { FuturePageEntity } from "../entities/future-page.entity";

export interface IFuturePageModel {
  id: number;
  pageYear: number;
  imageName: string;
  description: string;
  type: string;
  createdAt: string;
  updatedAt: string;
}

export class FuturePageModel {
  id: number;
  pageYear: number;
  imageName: string;
  description: string;
  type: string;
  createdAt: string;
  updatedAt: string;

  constructor(data: IFuturePageModel) {
    this.id = data.id;
    this.pageYear = data.pageYear;
    this.imageName = data.imageName;
    this.description = data.description;
    this.type = data.type;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }

  toEntity(): FuturePageEntity {
    return {
      id: this.id,
      pageYear: this.pageYear.toString(),
      imageName: this.imageName,
      description: this.description,
      type: this.type,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }
}
