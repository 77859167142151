import { PageType } from "../../../constants/types.constants";
import { PastPageEntity } from "../entities/past-page.entity";

export interface IPastPageModel {
  id: number;
  dateStamp: string;
  pageYear: number;
  imageName: string;
  headLine: string;
  description: string;
  type: PageType;
  createdAt: string;
  updatedAt: string;
}

export class PastPageModel {
  id: number;
  dateStamp: string;
  pageYear: number;
  imageName: string;
  headLine: string;
  description: string;
  type: PageType;
  createdAt: string;
  updatedAt: string;

  constructor(data: IPastPageModel) {
    this.id = data.id;
    this.dateStamp = data.dateStamp;
    this.pageYear = data.pageYear;
    this.imageName = data.imageName;
    this.headLine = data.headLine;
    this.description = data.description;
    this.type = data.type;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }

  toEntity(): PastPageEntity {
    return {
      id: this.id,
      dateStamp: this.dateStamp,
      pageYear: this.pageYear.toString(),
      imageName: this.imageName,
      headLine: this.headLine,
      description: this.description,
      type: this.type,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }
}
