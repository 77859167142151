import { PageType } from "../../../constants/types.constants";
import { EndPageCreateEntity } from "../entities/end-page-create.entity";

interface Params {
  imageName: string;
  description: string;
  type: PageType;
}

export class EndPageCreateModel {
  imageName: string;
  description: string;
  type: PageType;

  constructor(params: Params) {
    this.imageName = params.imageName;
    this.description = params.description;
    this.type = params.type;
  }

  static fromEntity = (entity: EndPageCreateEntity) =>
    new EndPageCreateModel({
      ...entity,
    });
}
