import { Outlet, Route, Routes } from "react-router-dom";
import NotFoundPage from "./components/404";
import ComingSoonPage from "./components/coming-soon";
import ProtectedRoute from "./components/protected-route";
import { BASE_PATHS } from "./constants/path.constants";
import AdminsPage from "./modules/admins/pages/admins.page";
import ViewAdminPage from "./modules/admins/pages/view-admin.page";
import LoginPage from "./modules/auth/pages/login.page";
import ResetPasswordPage from "./modules/auth/pages/reset-password.page";
import BirthPagesPage from "./modules/birth-pages/pages/birth-pages.page";
import ViewBirthPage from "./modules/birth-pages/pages/view-birth-page.page";
import DashboardPage from "./modules/dashboard/pages/dashboard.page";
import EndPagesPage from "./modules/end-pages/pages/end-pages.page";
import ViewEndPage from "./modules/end-pages/pages/view-end-page.page";
import FuturePagesPage from "./modules/future-pages/pages/future-pages.page";
import ViewFuturePage from "./modules/future-pages/pages/view-future-page.page";
import PastPagesPage from "./modules/past-pages/pages/past-pages.page";
import ViewPastPage from "./modules/past-pages/pages/view-past-page.page";
import QuizardsPage from "./modules/quizards/pages/quizard.page";
import ViewQuizardPage from "./modules/quizards/pages/view-quizard.page";
import UsersPage from "./modules/users/pages/users.page";
import ViewUserPage from "./modules/users/pages/view-user.page";

function App() {
  return (
    <Routes>
      <Route path={BASE_PATHS.login} element={<LoginPage />} />
      <Route path={BASE_PATHS.resetPassword} element={<ResetPasswordPage />} />
      <Route
        path={BASE_PATHS.home}
        element={
          <ProtectedRoute>
            <Outlet />
          </ProtectedRoute>
        }
      >
        <Route path={BASE_PATHS.current} element={<DashboardPage />} />
        <Route path={BASE_PATHS.admins} element={<Outlet />}>
          <Route path={BASE_PATHS.current} element={<AdminsPage />} />
          <Route path={BASE_PATHS.view} element={<ViewAdminPage />} />
        </Route>
        <Route path={BASE_PATHS.users} element={<Outlet />}>
          <Route path={BASE_PATHS.current} element={<UsersPage />} />
          <Route path={BASE_PATHS.view} element={<ViewUserPage />} />
        </Route>
        <Route path={BASE_PATHS.pastPages} element={<Outlet />}>
          <Route path={BASE_PATHS.current} element={<PastPagesPage />} />
          <Route path={BASE_PATHS.view} element={<ViewPastPage />} />
        </Route>
        <Route path={BASE_PATHS.futurePages} element={<Outlet />}>
          <Route path={BASE_PATHS.current} element={<FuturePagesPage />} />
          <Route path={BASE_PATHS.view} element={<ViewFuturePage />} />
        </Route>
        <Route path={BASE_PATHS.birthPages} element={<Outlet />}>
          <Route path={BASE_PATHS.current} element={<BirthPagesPage />} />
          <Route path={BASE_PATHS.view} element={<ViewBirthPage />} />
        </Route>
        <Route path={BASE_PATHS.endPages} element={<Outlet />}>
          <Route path={BASE_PATHS.current} element={<EndPagesPage />} />
          <Route path={BASE_PATHS.view} element={<ViewEndPage />} />
        </Route>
        <Route path={BASE_PATHS.quizards} element={<Outlet />}>
          <Route path={BASE_PATHS.current} element={<QuizardsPage />} />
          <Route path={BASE_PATHS.view} element={<ViewQuizardPage />} />
        </Route>
        <Route path="album-themes" element={<ComingSoonPage />} />
        <Route path="preferences" element={<ComingSoonPage />} />
      </Route>
      <Route path="*" element={<NotFoundPage />}></Route>
    </Routes>
  );
}

export default App;
