import { create } from "zustand";
import { CurrentUserEntity } from "../entities/current-user.entity";

interface CurrentUserState {
  user: CurrentUserEntity | null;
  setUser: (user: CurrentUserEntity) => void;
  clearUser: () => void;
}

export const useUserStore = create<CurrentUserState>((set, get) => ({
  user: null,
  setUser: (user: CurrentUserEntity) => {
    set({ user });
  },
  clearUser: () => {
    set({ user: null });
  },
}));
