import { PageType } from "../../../constants/types.constants";
import { BirthPageDetailsEntity } from "../entities/birth-page-details.entity";

interface Params {
  id: number;
  dateStamp: string;
  pageYear: string;
  imageName: string;
  headLine: string;
  description: string;
  type: PageType;
  createdAt: string;
  updatedAt: string;
  imageUrl: string;
}

export class BirthPageDetailsModel {
  id: number;
  imageName: string;
  headLine: string;
  description: string;
  type: PageType;
  createdAt: string;
  updatedAt: string;
  imageUrl: string;

  constructor(params: Params) {
    this.id = params.id;
    this.imageName = params.imageName;
    this.headLine = params.headLine;
    this.description = params.description;
    this.type = params.type;
    this.createdAt = params.createdAt;
    this.updatedAt = params.updatedAt;
    this.imageUrl = params.imageUrl;
  }

  toEntity(): BirthPageDetailsEntity {
    return {
      id: this.id,
      imageName: this.imageName,
      headLine: this.headLine,
      description: this.description,
      type: this.type,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      imageUrl: this.imageUrl,
    };
  }
}
