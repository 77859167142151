import { PageType } from "../constants/types.constants";

interface Params {
  id: number;
  imageName: string;
  imageUrl: string;
  description: string;
  type: PageType;
}

export class PageEditBaseEntity {
  id: number;
  imageName: string;
  imageUrl: string;
  description: string;
  type: PageType;

  constructor(params: Params) {
    this.id = params.id;
    this.imageName = params.imageName;
    this.imageUrl = params.imageUrl;
    this.description = params.description;
    this.type = params.type;
  }
}
