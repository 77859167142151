import { useLayoutEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Pagination, { PaginationData } from "./pagination";

export interface TableHeader {
  title: string;
  contentKey: string;
}

export default function TableComponent({
  headers,
  content,
  viewHref,
  paginationData,
  onPaginationClick,
}: {
  headers: TableHeader[];
  content: any[];
  viewHref: string;
  paginationData?: PaginationData;
  onPaginationClick?: (page: number) => void;
}) {
  const checkbox = useRef<any>();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedContent, setSelectedContent] = useState<any>([]);

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedContent.length > 0 && selectedContent.length < content.length;
    setChecked(content.length > 0 && selectedContent.length === content.length);
    setIndeterminate(isIndeterminate);
    checkbox.current.indeterminate = isIndeterminate;
  }, [selectedContent, content]);

  function toggleAll() {
    setSelectedContent(checked || indeterminate ? [] : content);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  return (
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="relative">
            {selectedContent.length > 0 && (
              <div className="absolute left-14 top-0 flex h-12 items-center space-x-3 bg-white sm:left-12">
                <button
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                >
                  Bulk edit
                </button>
                <button
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                >
                  Delete all
                </button>
              </div>
            )}
            <table className="min-w-full table-fixed divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                    <input
                      type="checkbox"
                      className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      ref={checkbox}
                      checked={checked}
                      onChange={toggleAll}
                    />
                  </th>

                  {headers.map((header: TableHeader) => (
                    <th
                      key={header.contentKey}
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {header.title}
                    </th>
                  ))}
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {content.map((item) => (
                  <tr
                    key={item.id}
                    className={
                      selectedContent.includes(item) ? "bg-gray-50" : undefined
                    }
                  >
                    <td className="relative px-7 sm:w-12 sm:px-6">
                      {selectedContent.includes(item) && (
                        <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                      )}
                      <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        value={item.email}
                        checked={selectedContent.includes(item)}
                        onChange={(e) =>
                          setSelectedContent(
                            e.target.checked
                              ? [...selectedContent, item]
                              : selectedContent.filter((p: any) => p !== item)
                          )
                        }
                      />
                    </td>
                    {headers.map((header: TableHeader) => (
                      <td
                        key={header.contentKey}
                        className="px-3 py-4 text-sm text-gray-500"
                      >
                        {item[header.contentKey]}
                      </td>
                    ))}
                    <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                      <Link
                        to={`${viewHref}?id=${item.id}`}
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        View<span className="sr-only">, {item.name}</span>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {content.length > 0 && paginationData && (
        <Pagination data={paginationData} onClick={onPaginationClick!} />
      )}
    </div>
  );
}
