import {
  CursorArrowRaysIcon,
  EnvelopeOpenIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import Layout from "../../../components/dashboard-layout/layout";
import { getStatistics } from "../apis/dashboard.api";
import InfoCardComponent from "../components/info-card.component";
import StatCardComponent, {
  StatCardItem,
} from "../components/stat-card.component";
import { StatisticsEntity } from "../entities/statistics.entity";
import { StatisticsModel } from "../models/statistics.model";
import { AlertType, useDashboardStore } from "../store/dashboard.store";

export default function DashboardPage() {
  const initEffect = useRef<boolean>(false);
  const setAlert = useDashboardStore((state) => state.setAlert);
  const [stats, setStats] = useState<StatCardItem[]>([]);
  const [kpis, setKpis] = useState<StatCardItem[]>([]);

  const getStatsFromApi = (statistics: StatisticsEntity): StatCardItem[] => {
    if (!statistics) return [];

    return [
      {
        id: 1,
        name: "Active Users",
        stat: statistics.activeUsers.toString(),
        icon: UsersIcon,
      },
      {
        id: 2,
        name: "Sessions",
        stat: statistics.sessions.toString(),
        icon: EnvelopeOpenIcon,
      },
      {
        id: 3,
        name: "User Pages",
        stat: statistics.userPages.toString(),
        icon: CursorArrowRaysIcon,
      },
      {
        id: 4,
        name: "12 Month Growth",
        stat: statistics.growth12Months.toString(),
        icon: CursorArrowRaysIcon,
      },
      {
        id: 5,
        name: "Average Session Time",
        stat: statistics.avgSessionTime.toString(),
        icon: CursorArrowRaysIcon,
      },
      {
        id: 6,
        name: "Average User Pages",
        stat: statistics.avgUserPages.toString(),
        icon: CursorArrowRaysIcon,
      },
    ];
  };

  const getKpisFromApi = (statistics: StatisticsEntity): StatCardItem[] => {
    if (!statistics) return [];
    return [
      {
        id: 1,
        name: "Total Past Pages",
        stat: statistics.pastPages.toString(),
        icon: UsersIcon,
      },
      {
        id: 2,
        name: "Quizards",
        stat: statistics.quizards.toString(),
        icon: EnvelopeOpenIcon,
      },
      {
        id: 3,
        name: "Covers & Frames",
        stat: statistics.coversAndFrames.toString(),
        icon: CursorArrowRaysIcon,
      },
      {
        id: 4,
        name: "Sponsors",
        stat: statistics.sponsors.toString(),
        icon: CursorArrowRaysIcon,
      },
      {
        id: 5,
        name: "Store Products",
        stat: statistics.storeProducts.toString(),
        icon: CursorArrowRaysIcon,
      },
      {
        id: 6,
        name: "Premium Users",
        stat: statistics.premiumUsers.toString(),
        icon: CursorArrowRaysIcon,
      },
    ];
  };

  useEffect(() => {
    if (initEffect.current === false) {
      getStatistics()
        .then((res) => {
          const statistics = new StatisticsModel(res.data).toEntity();
          setStats(getStatsFromApi(statistics));
          setKpis(getKpisFromApi(statistics));
        })
        .catch((error) => {
          setAlert({
            message: error.response.data.message,
            type: AlertType.error,
          });
        });
      return () => {
        initEffect.current = true;
      };
    }
  }, [setAlert]);

  return (
    <Layout>
      <div>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {stats.map((item) => (
            <StatCardComponent key={item.id} item={item} />
          ))}
        </dl>
        <div className="flex justify-center items-center h-60">
          <p className="">Graph goes here</p>
        </div>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <InfoCardComponent title="Average Session Time">
            <div className="p-10"></div>
          </InfoCardComponent>
          <InfoCardComponent title="By Device Type">
            <div className="p-10"></div>
          </InfoCardComponent>
          <InfoCardComponent title="By Gender">
            <div className="p-10"></div>
          </InfoCardComponent>
          <InfoCardComponent title="By Location">
            <div className="p-10"></div>
          </InfoCardComponent>
          <InfoCardComponent title="New Users">
            <div className="p-10"></div>
          </InfoCardComponent>
          <InfoCardComponent title="Average User Account Age">
            <div className="p-10"></div>
          </InfoCardComponent>
        </dl>
        <h2 className="text-2xl pt-10">Content KPIs</h2>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {kpis.map((item) => (
            <StatCardComponent key={item.id} item={item} />
          ))}
        </dl>
      </div>
    </Layout>
  );
}
