export enum TopicType {
  string,
  date,
  dateTime,
  image,
  video,
}

export interface ViewTopic {
  title: string;
  contentKey: string;
  type: TopicType;
}

export default function ViewDetailsComponent({
  topics,
  content,
}: {
  topics: ViewTopic[];
  content: any;
}) {
  return (
    <div className="mt-6 border-t border-gray-100">
      <dl className="divide-y divide-gray-100">
        {topics.map((topic: ViewTopic) => {
          console.log(topic.type, TopicType.video);
          return (
            <div
              key={topic.title}
              className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
            >
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {topic.title}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {content[topic.contentKey] ? (
                  topic.type === TopicType.dateTime ? (
                    new Date(content[topic.contentKey]).toUTCString()
                  ) : topic.type === TopicType.date ? (
                    new Date(content[topic.contentKey]).toDateString()
                  ) : topic.type === TopicType.image ? (
                    <img
                      className="max-w-md max-h-md"
                      src={content[topic.contentKey]}
                      alt={topic.title}
                    />
                  ) : topic.type === TopicType.video ? (
                    <video
                      className="max-w-md max-h-md"
                      controls
                      controlsList="nofullscreen nodownload noremoteplayback noplaybackrate "
                      src={content[topic.contentKey]}
                    >
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    content[topic.contentKey]
                  )
                ) : (
                  "-"
                )}
              </dd>
            </div>
          );
        })}
      </dl>
    </div>
  );
}
