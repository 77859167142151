import { PageType } from "../../../constants/types.constants";
import { PageCreateBaseEntity } from "../../../entities/page-create-base.entity";

interface Params {
  pageYear: string;
  dateStamp: string;
  imageName: string;
  headLine: string;
  description: string;
}

export class PastPageCreateEntity extends PageCreateBaseEntity {
  pageYear: string;
  headLine: string;
  dateStamp: string;

  constructor(params: Params) {
    super({ ...params, type: PageType.past });
    this.pageYear = params.pageYear;
    this.imageName = params.imageName;
    this.headLine = params.headLine;
    this.dateStamp = params.dateStamp;
  }

  static createEmpty = (): PastPageCreateEntity => {
    return new PastPageCreateEntity({
      pageYear: "",
      dateStamp: "",
      imageName: "",
      headLine: "",
      description: "",
    });
  };
}
