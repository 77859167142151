import { PageType } from "../constants/types.constants";

interface Params {
  imageName: string;
  description: string;
  type: PageType;
}

export class PageCreateBaseEntity {
  imageName: string;
  description: string;
  type: PageType;

  constructor(params: Params) {
    this.imageName = params.imageName;
    this.description = params.description;
    this.type = params.type;
  }
}
