import FormElement from "./form-element";
import { FormParams } from "./input";

export interface Option {
  id: string;
  value: string;
}
export interface SelectParams extends FormParams {
  options: Option[];
}

export default function Select(params: SelectParams) {
  return (
    <FormElement
      id={params.id}
      label={params.label}
      className={params.className}
    >
      <select
        id={params.id}
        name={params.id}
        onChange={params.onChange}
        required={params.required}
        value={params.value}
        className="w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      >
        {params.options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.value}
          </option>
        ))}
      </select>
    </FormElement>
  );
}
