import EditPageDialog from "../../../components/edit-page.dialog";
import { FormItem, FormType } from "../../../components/form/form-generator";
import { FileUploadCategory } from "../../../services/file-upload.service";
import {
  AlertType,
  useDashboardStore,
} from "../../dashboard/store/dashboard.store";
import { updatePastPage } from "../apis/past-page.api";
import { PastPageDetailsEntity } from "../entities/past-page-details.entity";
import { PastPageEditEntity } from "../entities/past-page-edit.entity";
import { PastPageEditModel } from "../models/past-page-edit.model";

const formElements: FormItem[] = [
  {
    title: "Year",
    contentKey: "pageYear",
    type: FormType.number,
  },
  {
    title: "Date",
    contentKey: "dateStamp",
    type: FormType.date,
  },
  {
    title: "Head Line",
    contentKey: "headLine",
    type: FormType.text,
  },
  {
    title: "Description",
    contentKey: "description",
    type: FormType.textArea,
  },
];

export default function EditPastPageDialog({
  open,
  setOpen,
  pastPage,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  pastPage: PastPageDetailsEntity;
}) {
  const setAlert = useDashboardStore((state) => state.setAlert);
  const onSave = async (values: any) => {
    updatePastPage(PastPageEditModel.fromEntity(values))
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        setAlert({
          message: error.response.data.message,
          type: AlertType.error,
        });
      });
  };

  return (
    <EditPageDialog
      title="Edit Past Page"
      open={open}
      setOpen={setOpen}
      page={PastPageEditEntity.fromDetailsEntity(pastPage)}
      formElements={formElements}
      onSaveClick={onSave}
      uploadCategory={FileUploadCategory.PAST}
    />
  );
}
