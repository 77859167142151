interface IUserLoginEntity {
  email: string;
  password: string;
}

export class UserLoginEntity {
  email: string;
  password: string;

  constructor(params: IUserLoginEntity) {
    this.email = params.email;
    this.password = params.password;
  }

  static createEmpty = (): UserLoginEntity => {
    return new UserLoginEntity({
      email: "",
      password: "",
    });
  };
}
