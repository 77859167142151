import { useForm } from "../../hooks/use-form.hook";
import FormGenerator, { FormItem } from "./form-generator";

export default function DialogFormComponent({
  elements,
  content,
  onClickSubmit,
  submitButtonTitle,
  onClickCancel,
}: {
  elements: FormItem[];
  content: any;
  onClickSubmit: (values: any) => void;
  submitButtonTitle: string;
  onClickCancel: () => void;
}) {
  const onSubmit = () => {
    onClickSubmit(formFunc.values);
  };

  const formFunc = useForm<any>(onSubmit, content);

  return (
    <form onSubmit={formFunc.onSubmit}>
      <FormGenerator
        elements={elements}
        values={formFunc.values}
        onChange={formFunc.onChange}
      />
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="submit"
          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
        >
          {submitButtonTitle}
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={() => onClickCancel()}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}
