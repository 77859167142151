import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { useUserStore } from "../../current-user/store/current-user.store";

export enum AuthStatus {
  LOGGED_IN = "loggedOut",
  LOGGED_OUT = "loggedIn",
}

interface AuthState {
  token: string | null;
  status: AuthStatus;
  login: (token: string) => void;
  logout: () => void;
}

export const useAuthStore = create(
  persist<AuthState>(
    (set, get) => ({
      status: AuthStatus.LOGGED_OUT,
      token: null,
      initial: false,
      login: (token: string) => {
        set({ status: AuthStatus.LOGGED_IN, token });
      },
      logout: () => {
        useUserStore.getState().clearUser();
        set({ status: AuthStatus.LOGGED_OUT, token: null });
      },
    }),
    {
      name: "auth",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
