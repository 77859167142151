import { PencilSquareIcon } from "@heroicons/react/24/outline";
import DialogLayout from "../../../components/dialog-layout";
import DialogFormComponent from "../../../components/form/dialog-form.component";
import { FormItem, FormType } from "../../../components/form/form-generator";
import {
  AlertType,
  useDashboardStore,
} from "../../dashboard/store/dashboard.store";
import { updateAdmin } from "../apis/admins.api";
import { AdminDetailsEntity } from "../entities/admin-details.entity";
import { AdminUpdateModel } from "../models/admin-update.model";

const formElements: FormItem[] = [
  {
    title: "First Name",
    contentKey: "firstName",
    type: FormType.text,
  },
  {
    title: "Last Name",
    contentKey: "lastName",
    type: FormType.text,
  },
];

export default function EditAdminDialog({
  open,
  setOpen,
  admin,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  admin: AdminDetailsEntity;
}) {
  const setAlert = useDashboardStore((state) => state.setAlert);

  const onSave = async (values: any) => {
    updateAdmin(AdminUpdateModel.fromEntity(values))
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        setOpen(false);
        setAlert({
          message: error.response.data.message,
          type: AlertType.error,
        });
      });
  };

  return (
    <DialogLayout
      title="Edit Admin"
      open={open}
      setOpen={setOpen}
      Icon={PencilSquareIcon}
    >
      <div className="mt-2">
        <DialogFormComponent
          elements={formElements}
          content={admin}
          submitButtonTitle="Save"
          onClickSubmit={onSave}
          onClickCancel={() => setOpen(false)}
        />
      </div>
    </DialogLayout>
  );
}
