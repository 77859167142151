import { PageType } from "../../../constants/types.constants";
import { PastPageDetailsEntity } from "../entities/past-page-details.entity";

export interface IPastPageDetailsModel {
  id: number;
  dateStamp: string;
  pageYear: number;
  imageName: string;
  headLine: string;
  description: string;
  type: PageType;
  createdAt: string;
  updatedAt: string;
  imageUrl: string;
}

export class PastPageDetailsModel {
  id: number;
  dateStamp: string;
  pageYear: number;
  imageName: string;
  headLine: string;
  description: string;
  type: PageType;
  createdAt: string;
  updatedAt: string;
  imageUrl: string;

  constructor(data: IPastPageDetailsModel) {
    this.id = data.id;
    this.dateStamp = data.dateStamp;
    this.pageYear = data.pageYear;
    this.imageName = data.imageName;
    this.headLine = data.headLine;
    this.description = data.description;
    this.type = data.type;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.imageUrl = data.imageUrl;
  }

  toEntity(): PastPageDetailsEntity {
    return {
      id: this.id,
      dateStamp: this.dateStamp,
      pageYear: this.pageYear.toString(),
      imageName: this.imageName,
      headLine: this.headLine,
      description: this.description,
      type: this.type,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      imageUrl: this.imageUrl,
    };
  }
}
