import { UserEntity } from "../entities/user.entity";

export interface IUserModel {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  lastLoginDateTime: string;
}

export class UserModel {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  lastLoginDateTime: string;

  constructor(data: IUserModel) {
    this.id = data.id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.lastLoginDateTime = data.lastLoginDateTime;
  }

  toEntity(): UserEntity {
    return {
      id: this.id,
      name: `${this.firstName} ${this.lastName}`,
      email: this.email,
      lastLogin: this.lastLoginDateTime,
    };
  }
}
