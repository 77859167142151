import { PlusCircleIcon } from "@heroicons/react/24/outline";
import DialogLayout from "../../../components/dialog-layout";
import DialogFormComponent from "../../../components/form/dialog-form.component";
import { FormItem, FormType } from "../../../components/form/form-generator";
import {
  AlertType,
  useDashboardStore,
} from "../../dashboard/store/dashboard.store";
import { createAdmin } from "../apis/admins.api";
import { AdminDetailsEntity } from "../entities/admin-details.entity";
import { AdminCreateModel } from "../models/admin-create.model";

const formElements: FormItem[] = [
  {
    title: "First Name",
    contentKey: "firstName",
    type: FormType.text,
  },
  {
    title: "Last Name",
    contentKey: "lastName",
    type: FormType.text,
  },
  {
    title: "Email",
    contentKey: "email",
    type: FormType.email,
  },
];

export default function AddAdminDialog({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const setAlert = useDashboardStore((state) => state.setAlert);

  const onSave = async (values: any) => {
    createAdmin(AdminCreateModel.fromEntity(values))
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        setOpen(false);
        setAlert({
          message: error.response.data.message,
          type: AlertType.error,
        });
      });
  };

  return (
    <DialogLayout
      title="Add Admin"
      open={open}
      setOpen={setOpen}
      Icon={PlusCircleIcon}
    >
      <div className="mt-2">
        <DialogFormComponent
          elements={formElements}
          content={AdminDetailsEntity.createEmpty()}
          onClickSubmit={onSave}
          submitButtonTitle="Create"
          onClickCancel={() => setOpen(false)}
        />
      </div>
    </DialogLayout>
  );
}
