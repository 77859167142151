import { AdminDetailsEntity } from "../entities/admin-details.entity";

export interface IAdminUpdateModel {
  id: number;
  firstName: string;
  lastName: string;
  roles: string[];
}

export class AdminUpdateModel {
  id: number;
  firstName: string;
  lastName: string;
  roles: string[];

  constructor(data: IAdminUpdateModel) {
    this.id = data.id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.roles = data.roles;
  }

  static fromEntity = (entity: AdminDetailsEntity) => {
    return new AdminUpdateModel({
      id: entity.id,
      firstName: entity.firstName,
      lastName: entity.lastName,
      roles: ["SUPER_ADMIN"],
    });
  };
}
