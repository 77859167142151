import { PageType } from "../../../constants/types.constants";
import { PageEditBaseEntity } from "../../../entities/page-edit-base.entity";
import { PastPageDetailsEntity } from "./past-page-details.entity";

interface Params {
  id: number;
  imageName: string;
  pageYear: string;
  dateStamp: string;
  headLine: string;
  description: string;
  type: PageType;
  imageUrl: string;
}

export class PastPageEditEntity extends PageEditBaseEntity {
  headLine: string;
  pageYear: string;
  dateStamp: string;
  constructor(params: Params) {
    super({ ...params });
    this.pageYear = params.pageYear;
    this.headLine = params.headLine;
    this.dateStamp = params.dateStamp;
  }

  static fromDetailsEntity(entity: PastPageDetailsEntity) {
    return new PastPageEditEntity({
      ...entity,
      dateStamp: entity.dateStamp ? entity.dateStamp.substring(0, 10) : "",
    });
  }
}
