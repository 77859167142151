import { PageType } from "../../../constants/types.constants";
import { PageCreateBaseEntity } from "../../../entities/page-create-base.entity";

interface Params {
  pageYear: string;
  imageName: string;
  description: string;
}

export class FuturePageCreateEntity extends PageCreateBaseEntity {
  pageYear: string;

  constructor(params: Params) {
    super({ ...params, type: PageType.future });
    this.pageYear = params.pageYear;
  }

  static createEmpty = (): FuturePageCreateEntity => {
    return new FuturePageCreateEntity({
      pageYear: "",
      imageName: "",
      description: "",
    });
  };
}
