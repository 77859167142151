import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import FormElement from "./form-element";
import Input, { FormParams } from "./input";

export default function SearchInput(params: FormParams) {
  return (
    <div className="relative w-full">
      <MagnifyingGlassIcon
        className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
        aria-hidden="true"
      />
      <FormElement id={params.id} className={`pl-8 ${params.className ?? ""}`}>
        <Input type="search" {...params} />
      </FormElement>
    </div>
  );
}
