import { UserLoginEntity } from "../entities/user-login.entity";

export interface IUserLoginModel {
  email: string;
  password: string;
}

export class UserLoginModel {
  email: string;
  password: string;

  constructor(data: IUserLoginModel) {
    this.email = data.email;
    this.password = data.password;
  }

  static fromEntity(entity: UserLoginEntity) {
    return new UserLoginModel({
      email: entity.email,
      password: entity.password,
    });
  }
}
