import { PageType } from "../../../constants/types.constants";
import { PageCreateBaseEntity } from "../../../entities/page-create-base.entity";

interface Params {
  imageName: string;
  description: string;
}

export class BirthPageCreateEntity extends PageCreateBaseEntity {
  constructor(params: Params) {
    super({ ...params, type: PageType.birth });
  }

  static createEmpty = (): BirthPageCreateEntity => {
    return new BirthPageCreateEntity({
      imageName: "",
      description: "",
    });
  };
}
