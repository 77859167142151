import { useForm } from "../../hooks/use-form.hook";
import FormGenerator, { FormItem } from "./form-generator";

export default function PageFormComponent({
  elements,
  content,
  onClickSubmit,
  submitButtonTitle,
}: {
  elements: FormItem[];
  content: any;
  onClickSubmit: (values: any) => void;
  submitButtonTitle: string;
}) {
  const onSubmit = () => {
    onClickSubmit(formFunc.values);
  };

  const formFunc = useForm<any>(onSubmit, content);

  return (
    <form onSubmit={formFunc.onSubmit}>
      <FormGenerator
        elements={elements}
        values={formFunc.values}
        onChange={formFunc.onChange}
      />
      <div className="mt-8 flex flex-row-reverse">
        <button
          type="submit"
          className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          {submitButtonTitle}
        </button>
      </div>
    </form>
  );
}
