import { PageType } from "../../../constants/types.constants";
import { http } from "../../../services/http.service";
import { EndPageCreateModel } from "../models/end-page-create.model";
import { EndPageEditModel } from "../models/end-page-edit.model";

export const getEndPages = (
  paginatedPageId: string,
  searchString: string | null
) =>
  http({
    url: "default-pages",
    method: "GET",
    auth: true,
    params: {
      paginatedPageId,
      paginatedPageLimit: 20,
      type: PageType.end,
      searchString,
    },
  });

export const getEndPageById = (id: string) =>
  http({
    url: "default-pages/getById",
    method: "GET",
    auth: true,
    params: {
      id,
    },
  });

export const createEndPage = (data: EndPageCreateModel) =>
  http({
    url: "default-pages",
    method: "POST",
    auth: true,
    data,
  });

export const updateEndPage = (data: EndPageEditModel) =>
  http({
    url: "default-pages",
    method: "PUT",
    auth: true,
    data,
  });

export const deleteEndPage = (id: number) =>
  http({
    url: "default-pages",
    method: "DELETE",
    auth: true,
    params: {
      id,
    },
  });
