import axios, { Method } from "axios";
import { useAuthStore } from "../modules/auth/store/auth.store";

const BASE_URL = process.env.REACT_APP_API;

export interface RequestConfig {
  url: string;
  method: Method;
  auth: boolean;
  data?: any;
  headers?: any;
  params?: any;
}

export const http = async (config: RequestConfig) => {
  const headers = config.headers ? config.headers : {};
  const url = `${BASE_URL}/${config.url}`;
  if (config.auth && !headers?.Authorization) {
    const token = useAuthStore.getState().token;
    headers.Authorization = `Bearer ${token}`;
  }

  return await axios({
    method: config.method,
    url,
    data: config.data,
    headers,
    params: config.params,
  });
};

axios.interceptors.request.use(
  function (config) {
    // spinning start to show
    // UPDATE: Add this code to show global loading indicator
    document.body.classList.add("loading-indicator");
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    document.body.classList.remove("loading-indicator");
    return response;
  },
  (error) => {
    document.body.classList.remove("loading-indicator");

    if (error.response.status === 401) {
      useAuthStore.getState().logout();
    }
    return Promise.reject(error);
  }
);
