import { PageType } from "../../../constants/types.constants";
import { BirthPageEditEntity } from "../entities/birth-page-edit.entity";

interface Params {
  id: number;
  imageName: string;
  description: string;
  type: PageType;
}

export class BirthPageEditModel {
  id: number;
  imageName: string;
  description: string;
  type: PageType;

  constructor(params: Params) {
    this.id = params.id;
    this.imageName = params.imageName;
    this.description = params.description;
    this.type = params.type;
  }

  static fromEntity(entity: BirthPageEditEntity) {
    return new BirthPageEditModel({
      ...entity,
    });
  }
}
