import { QuizardDetailsEntity } from "../entities/quizard-details.entity";

export interface IQuizardDetailsModel {
  id: number;
  title: string;
  description: string;
  imageName: string;
  imageUrl: string;
  videoName: string;
  videoUrl: string;
  type: string;
  createdAt: string;
  tag: string;
}

export class QuizardDetailsModel {
  id: number;
  title: string;
  description: string;
  imageName: string;
  imageUrl: string;
  videoName: string;
  videoUrl: string;
  type: string;
  createdAt: string;
  tag: string;

  constructor(data: IQuizardDetailsModel) {
    this.id = data.id;
    this.title = data.title;
    this.description = data.description;
    this.imageName = data.imageName;
    this.imageUrl = data.imageUrl;
    this.videoName = data.videoName;
    this.videoUrl = data.videoUrl;
    this.type = data.type;
    this.createdAt = data.createdAt;
    this.tag = data.tag;
  }

  toEntity(): QuizardDetailsEntity {
    return {
      id: this.id,
      title: this.title,
      description: this.description,
      imageName: this.imageName,
      imageUrl: this.imageUrl,
      videoName: this.videoName,
      videoUrl: this.videoUrl,
      type: this.type,
      createdAt: this.createdAt,
      albumTag: this.tag,
    };
  }
}
