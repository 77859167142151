import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { PageEditBaseEntity } from "../entities/page-edit-base.entity";
import {
  FileUploadCategory,
  uploadFile,
} from "../services/file-upload.service";
import DialogLayout from "./dialog-layout";
import DialogFormComponent from "./form/dialog-form.component";
import { FormItem } from "./form/form-generator";
import ImageUploader from "./image-uploader";

export default function EditPageDialog<T extends PageEditBaseEntity>({
  title,
  open,
  setOpen,
  page,
  formElements,
  onSaveClick,
  uploadCategory,
}: {
  title: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  page: T;
  formElements: FormItem[];
  onSaveClick: (page: T) => void;
  uploadCategory: FileUploadCategory;
}) {
  const [imageFile, setImageFile] = useState<File>();

  const onSave = async (values: T) => {
    if (imageFile) {
      values.imageName = await uploadFile(imageFile, uploadCategory);
    }
    onSaveClick(values);
  };

  return (
    <DialogLayout
      title={title}
      open={open}
      setOpen={setOpen}
      Icon={PlusCircleIcon}
    >
      <div className="mt-6">
        <ImageUploader
          id="coverImage"
          label="Cover Image"
          setImageFile={setImageFile}
          previewUrl={page.imageUrl}
        />
        <DialogFormComponent
          elements={formElements}
          content={page}
          onClickSubmit={onSave}
          submitButtonTitle="Save"
          onClickCancel={() => setOpen(false)}
        />
      </div>
    </DialogLayout>
  );
}
