import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Layout from "../../../components/dashboard-layout/layout";
import ViewDetailsComponent, {
  TopicType,
  ViewTopic,
} from "../../../components/view-details.component";
import { getAdminById } from "../apis/admins.api";
import DeleteAdminDialog from "../dialogs/delete-admin.dialog";
import EditAdminDialog from "../dialogs/edit-admin.dialog";
import { AdminDetailsEntity } from "../entities/admin-details.entity";
import { AdminDetailsModel } from "../models/admin-details.model";
import {
  AlertType,
  useDashboardStore,
} from "../../dashboard/store/dashboard.store";

const topics: ViewTopic[] = [
  {
    title: "First Name",
    contentKey: "firstName",
    type: TopicType.string,
  },
  {
    title: "Last Name",
    contentKey: "lastName",
    type: TopicType.string,
  },
  {
    title: "Email",
    contentKey: "email",
    type: TopicType.string,
  },
  {
    title: "Created At",
    contentKey: "createdAt",
    type: TopicType.dateTime,
  },
];

export default function ViewAdminPage() {
  const [searchParams] = useSearchParams();
  const [admin, setAdmin] = useState<AdminDetailsEntity>();
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const initEffect = useRef<boolean>(false);
  const setAlert = useDashboardStore((state) => state.setAlert);

  useEffect(() => {
    if (initEffect.current === false) {
      const id = searchParams.get("id");
      if (id) {
        getAdminById(id)
          .then((response) => {
            const newAdmin = new AdminDetailsModel(response.data).toEntity();
            setAdmin(newAdmin);
          })
          .catch((error) => {
            setAlert({
              message: error.response.data.message,
              type: AlertType.error,
            });
          });
      }
    }
    return () => {
      initEffect.current = true;
    };
  }, [searchParams, setAlert]);

  return (
    <Layout>
      {admin && (
        <EditAdminDialog
          open={editModalOpen}
          setOpen={setEditModalOpen}
          admin={admin}
        />
      )}
      {admin && (
        <DeleteAdminDialog
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
          admin={admin}
        />
      )}
      <div className="flex">
        <div className="flex-grow px-4 sm:px-0">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            Admin Details
          </h3>
        </div>
        <button
          className="mx-1 rounded-md bg-indigo-600 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => setEditModalOpen(true)}
        >
          Edit
        </button>
        <button
          className="mx-1 rounded-md bg-red-600 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => setDeleteModalOpen(true)}
        >
          Delete
        </button>
      </div>

      {admin && <ViewDetailsComponent topics={topics} content={admin} />}
    </Layout>
  );
}
