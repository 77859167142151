import { PageType } from "../../../constants/types.constants";
import { PageEditBaseEntity } from "../../../entities/page-edit-base.entity";
import { BirthPageDetailsEntity } from "./birth-page-details.entity";

interface Params {
  id: number;
  imageName: string;
  description: string;
  type: PageType;
  imageUrl: string;
}

export class BirthPageEditEntity extends PageEditBaseEntity {
  constructor(params: Params) {
    super({ ...params });
  }

  static fromDetailsEntity(entity: BirthPageDetailsEntity) {
    return new BirthPageEditEntity({
      ...entity,
    });
  }
}
