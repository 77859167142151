import EditPageDialog from "../../../components/edit-page.dialog";
import { FormItem, FormType } from "../../../components/form/form-generator";
import { FileUploadCategory } from "../../../services/file-upload.service";
import {
  AlertType,
  useDashboardStore,
} from "../../dashboard/store/dashboard.store";
import { updateEndPage } from "../apis/end-page.api";
import { EndPageDetailsEntity } from "../entities/end-page-details.entity";
import { EndPageEditEntity } from "../entities/end-page-edit.entity";
import { EndPageEditModel } from "../models/end-page-edit.model";

const formElements: FormItem[] = [
  {
    title: "Description",
    contentKey: "description",
    type: FormType.textArea,
  },
];

export default function EditEndPageDialog({
  open,
  setOpen,
  endPage,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  endPage: EndPageDetailsEntity;
}) {
  const setAlert = useDashboardStore((state) => state.setAlert);
  const onSave = async (values: any) => {
    updateEndPage(EndPageEditModel.fromEntity(values))
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        setAlert({
          message: error.response.data.message,
          type: AlertType.error,
        });
      });
  };

  return (
    <EditPageDialog
      title="Edit End Page"
      open={open}
      setOpen={setOpen}
      page={EndPageEditEntity.fromDetailsEntity(endPage)}
      formElements={formElements}
      onSaveClick={onSave}
      uploadCategory={FileUploadCategory.END}
    />
  );
}
