import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Layout from "../../../components/dashboard-layout/layout";
import ViewDetailsComponent, {
  TopicType,
  ViewTopic,
} from "../../../components/view-details.component";
import {
  AlertType,
  useDashboardStore,
} from "../../dashboard/store/dashboard.store";
import { getPastPageById } from "../apis/past-page.api";
import DeletePastPageDialog from "../dialogs/delete-past-page.dialog";
import EditPastPageDialog from "../dialogs/edit-past-page.dialog";
import { PastPageDetailsEntity } from "../entities/past-page-details.entity";
import { PastPageDetailsModel } from "../models/past-page-details.model";

const topics: ViewTopic[] = [
  {
    title: "Cover Image",
    contentKey: "imageUrl",
    type: TopicType.image,
  },
  {
    title: "Head Line",
    contentKey: "headLine",
    type: TopicType.string,
  },
  {
    title: "Description",
    contentKey: "description",
    type: TopicType.string,
  },
  {
    title: "Year",
    contentKey: "pageYear",
    type: TopicType.string,
  },
  {
    title: "Date",
    contentKey: "dateStamp",
    type: TopicType.dateTime,
  },
  {
    title: "Created At",
    contentKey: "createdAt",
    type: TopicType.dateTime,
  },
  {
    title: "Last Update",
    contentKey: "updatedAt",
    type: TopicType.dateTime,
  },
];

export default function ViewPastPage() {
  const [searchParams] = useSearchParams();
  const [pastPage, setPastPage] = useState<PastPageDetailsEntity>();
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const initEffect = useRef<boolean>(false);
  const setAlert = useDashboardStore((state) => state.setAlert);

  useEffect(() => {
    if (initEffect.current === false) {
      const id = searchParams.get("id");
      if (id) {
        getPastPageById(id)
          .then((response) => {
            const newPastPage = new PastPageDetailsModel(
              response.data
            ).toEntity();
            setPastPage(newPastPage);
          })
          .catch((error) => {
            setAlert({
              message: error.response.data.message,
              type: AlertType.error,
            });
          });
      }
    }
    return () => {
      initEffect.current = true;
    };
  }, [searchParams, setAlert]);

  return (
    <Layout>
      {pastPage && (
        <EditPastPageDialog
          open={editModalOpen}
          setOpen={setEditModalOpen}
          pastPage={pastPage}
        />
      )}
      {pastPage && (
        <DeletePastPageDialog
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
          pastPage={pastPage}
        />
      )}
      <div className="flex">
        <div className="flex-grow px-4 sm:px-0">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            Past Page Details
          </h3>
        </div>
        <button
          className="mx-1 rounded-md bg-indigo-600 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => setEditModalOpen(true)}
        >
          Edit
        </button>
        <button
          className="mx-1 rounded-md bg-red-600 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => setDeleteModalOpen(true)}
        >
          Delete
        </button>
      </div>

      {pastPage && <ViewDetailsComponent topics={topics} content={pastPage} />}
    </Layout>
  );
}
