import FormElement from "./form-element";
import { FormParams } from "./input";

export interface TextAreaParams extends FormParams {
  maxLength?: number;
}

export default function TextArea(params: TextAreaParams) {
  return (
    <FormElement
      id={params.id}
      label={params.label}
      className={params.className}
    >
      <div className="mt-1">
        <textarea
          rows={2}
          name={params.id}
          id={params.id}
          value={params.value}
          onChange={params.onChange}
          required={params.required}
          autoComplete={params.autoComplete}
          maxLength={params.maxLength}
          placeholder={params.placeholder}
          className="w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        ></textarea>
      </div>
    </FormElement>
  );
}
