import { useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Layout from "../../../components/dashboard-layout/layout";
import SearchInput from "../../../components/form/search-input";
import { PaginationData } from "../../../components/pagination";
import TableComponent, {
  TableHeader,
} from "../../../components/table.component";
import { PATHS } from "../../../constants/path.constants";
import { useForm } from "../../../hooks/use-form.hook";
import {
  AlertType,
  useDashboardStore,
} from "../../dashboard/store/dashboard.store";
import { getEndPages } from "../apis/end-page.api";
import AddPastPageDialog from "../dialogs/add-end-page.dialog";
import { EndPageEntity } from "../entities/end-page.entity";
import { EndPageModel, Params } from "../models/end-page.model";

const headers: TableHeader[] = [
  {
    title: "Description",
    contentKey: "description",
  },
];

export default function PastPagesPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const initEffect = useRef<boolean>(false);
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [endPages, setEndPages] = useState<EndPageEntity[]>([]);
  const setAlert = useDashboardStore((state) => state.setAlert);
  const [paginationData, setPaginationData] = useState<PaginationData>({
    currentPage: 1,
    total: 0,
    perPage: 20,
    totalPages: 0,
  });

  const onSearchClick = () => {
    const search =
      formFunc.values.searchString === "" ? null : formFunc.values.searchString;
    populatePastPageTable("1", search);
    setSearchParams({ page: "1", ...(search && { search }) });
  };

  const onPaginationClick = (page: number) => {
    const search = searchParams.get("search");
    populatePastPageTable(page.toString(), search);
    setSearchParams({ page: page.toString(), ...(search && { search }) });
  };

  const populatePastPageTable = useCallback(
    async (paginatedPageId = "1", searchString?: string | null) => {
      try {
        const response = await getEndPages(
          paginatedPageId,
          searchString ?? null
        );
        const pastPages = response.data.data.map((pastPage: Params) => {
          return new EndPageModel(pastPage).toEntity();
        });
        setEndPages(pastPages);
        setPaginationData((data) => ({
          ...data,
          currentPage: response.data.currentPage,
          total: response.data.totalDefaultPages,
          totalPages: response.data.totalPages,
        }));
      } catch (error: any) {
        setAlert({
          message: error.response.data.message,
          type: AlertType.error,
        });
      }
    },
    [setAlert]
  );

  useEffect(() => {
    if (initEffect.current === false) {
      const page = searchParams.get("page");
      const search = searchParams.get("search") ?? null;
      populatePastPageTable(page ?? undefined, search);
    }

    return () => {
      initEffect.current = true;
    };
  }, [populatePastPageTable, searchParams]);

  const formFunc = useForm(onSearchClick, {
    searchString: "",
  });

  return (
    <Layout>
      <AddPastPageDialog open={addModalOpen} setOpen={setAddModalOpen} />
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <form className="flex flex-1" onSubmit={formFunc.onSubmit}>
            <SearchInput
              id="searchString"
              placeholder="Search End Page"
              value={formFunc.values.searchString}
              onChange={formFunc.onChange}
            />
          </form>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => setAddModalOpen(true)}
            >
              Add End Page
            </button>
          </div>
        </div>
        <TableComponent
          headers={headers}
          content={endPages}
          viewHref={PATHS.viewEndPage}
          paginationData={paginationData}
          onPaginationClick={onPaginationClick}
        />
      </div>
    </Layout>
  );
}
