import { Breadcrumbs } from "../components/dashboard-layout/breadcrumb";

export const BASE_PATHS = {
  current: "",
  home: "",
  users: "users",
  view: "view",
  login: "login",
  resetPassword: "reset-password",
  admins: "admins",
  preferences: "preferences",
  pastPages: "past-pages",
  futurePages: "future-pages",
  birthPages: "birth-pages",
  endPages: "end-pages",
  quizards: "quizards",
};

export const PATHS = {
  home: [BASE_PATHS.home, BASE_PATHS.current].join("/"),
  users: [BASE_PATHS.home, BASE_PATHS.users].join("/"),
  viewUser: [BASE_PATHS.home, BASE_PATHS.users, BASE_PATHS.view].join("/"),
  admins: [BASE_PATHS.home, BASE_PATHS.admins].join("/"),
  viewAdmin: [BASE_PATHS.home, BASE_PATHS.admins, BASE_PATHS.view].join("/"),
  pastPages: [BASE_PATHS.home, BASE_PATHS.pastPages].join("/"),
  viewPastPage: [BASE_PATHS.home, BASE_PATHS.pastPages, BASE_PATHS.view].join(
    "/"
  ),
  futurePages: [BASE_PATHS.home, BASE_PATHS.futurePages].join("/"),
  viewFuturePage: [
    BASE_PATHS.home,
    BASE_PATHS.futurePages,
    BASE_PATHS.view,
  ].join("/"),
  birthPages: [BASE_PATHS.home, BASE_PATHS.birthPages].join("/"),
  viewBirthPage: [BASE_PATHS.home, BASE_PATHS.birthPages, BASE_PATHS.view].join(
    "/"
  ),
  endPages: [BASE_PATHS.home, BASE_PATHS.endPages].join("/"),
  viewEndPage: [BASE_PATHS.home, BASE_PATHS.endPages, BASE_PATHS.view].join(
    "/"
  ),
  login: [BASE_PATHS.home, BASE_PATHS.login].join("/"),
  preferences: [BASE_PATHS.home, BASE_PATHS.preferences].join("/"),
  quizards: [BASE_PATHS.home, BASE_PATHS.quizards].join("/"),
  viewQuizard: [BASE_PATHS.home, BASE_PATHS.quizards, BASE_PATHS.view].join(
    "/"
  ),
};

export const BREADCRUMBS: Breadcrumbs[] = [
  {
    path: PATHS.home,
    breadcrumbList: [],
  },
  {
    path: PATHS.users,
    breadcrumbList: [
      {
        name: "Users",
        href: "",
      },
    ],
  },
  {
    path: PATHS.viewUser,
    breadcrumbList: [
      {
        name: "Users",
        href: PATHS.users,
      },
      {
        name: "View User",
        href: "",
      },
    ],
  },
  {
    path: PATHS.admins,
    breadcrumbList: [
      {
        name: "Admins",
        href: "",
      },
    ],
  },
  {
    path: PATHS.viewAdmin,
    breadcrumbList: [
      {
        name: "Admins",
        href: PATHS.admins,
      },
      {
        name: "View Admin",
        href: "",
      },
    ],
  },
  {
    path: PATHS.pastPages,
    breadcrumbList: [
      {
        name: "Past Pages",
        href: "",
      },
    ],
  },
  {
    path: PATHS.viewPastPage,
    breadcrumbList: [
      {
        name: "Past Pages",
        href: PATHS.pastPages,
      },
      {
        name: "View Past Page",
        href: "",
      },
    ],
  },
  {
    path: PATHS.futurePages,
    breadcrumbList: [
      {
        name: "Future Pages",
        href: "",
      },
    ],
  },
  {
    path: PATHS.viewFuturePage,
    breadcrumbList: [
      {
        name: "Future Pages",
        href: PATHS.futurePages,
      },
      {
        name: "View Future Page",
        href: "",
      },
    ],
  },
  {
    path: PATHS.birthPages,
    breadcrumbList: [
      {
        name: "Birth Pages",
        href: "",
      },
    ],
  },
  {
    path: PATHS.viewBirthPage,
    breadcrumbList: [
      {
        name: "Birth Pages",
        href: PATHS.birthPages,
      },
      {
        name: "View Birth Page",
        href: "",
      },
    ],
  },
  {
    path: PATHS.endPages,
    breadcrumbList: [
      {
        name: "End Pages",
        href: "",
      },
    ],
  },
  {
    path: PATHS.viewEndPage,
    breadcrumbList: [
      {
        name: "End Pages",
        href: PATHS.endPages,
      },
      {
        name: "View End Page",
        href: "",
      },
    ],
  },
  {
    path: PATHS.quizards,
    breadcrumbList: [
      {
        name: "Quizards",
        href: "",
      },
    ],
  },
  {
    path: PATHS.viewQuizard,
    breadcrumbList: [
      {
        name: "Quizards",
        href: PATHS.quizards,
      },
      {
        name: "View Quizard",
        href: "",
      },
    ],
  },
];
