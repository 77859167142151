import { PageType } from "../../../constants/types.constants";
import { PageEditBaseEntity } from "../../../entities/page-edit-base.entity";
import { FuturePageDetailsEntity } from "./future-page-details.entity";

interface Params {
  id: number;
  pageYear: string;
  imageName: string;
  description: string;
  type: PageType;
  imageUrl: string;
}

export class FuturePageEditEntity extends PageEditBaseEntity {
  pageYear: string;

  constructor(params: Params) {
    super({ ...params });
    this.pageYear = params.pageYear;
  }

  static fromDetailsEntity(entity: FuturePageDetailsEntity) {
    return new FuturePageEditEntity({
      ...entity,
    });
  }
}
