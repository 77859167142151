export interface InputParams extends FormParams {
  type: string;
}

export interface FormParams {
  id: string;
  label?: string;
  required?: boolean;
  value?: any;
  onChange?: any;
  onInput?: any;
  autoComplete?: string;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
}

export default function Input(params: InputParams) {
  return (
    <input
      type={params.type}
      name={params.id}
      id={params.id}
      value={params.value}
      onChange={params.onChange}
      autoComplete={params.autoComplete}
      required={params.required}
      placeholder={params.placeholder}
      onInput={params.onInput}
      disabled={params.disabled}
      className="w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
    />
  );
}
