import { PageType } from "../../../constants/types.constants";
import { FuturePageEditEntity } from "../entities/future-page-edit.entity";

interface Params {
  id: number;
  pageYear: number;
  imageName: string;
  description: string;
  type: PageType;
}

export class FuturePageEditModel {
  id: number;
  pageYear: number;
  imageName: string;
  description: string;
  type: PageType;

  constructor(params: Params) {
    this.id = params.id;
    this.pageYear = params.pageYear;
    this.imageName = params.imageName;
    this.description = params.description;
    this.type = params.type;
  }

  static fromEntity(entity: FuturePageEditEntity) {
    return new FuturePageEditModel({
      ...entity,
      pageYear: parseInt(entity.pageYear),
    });
  }
}
