import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ErrorMessageComponent from "../../../components/error-message.component";
import { FormItem, FormType } from "../../../components/form/form-generator";
import PageFormComponent from "../../../components/form/page-form.component";
import { PATHS } from "../../../constants/path.constants";
import { loginApi } from "../apis/auth.api";
import { UserLoginEntity } from "../entities/user-login.entity";
import { UserLoginModel } from "../models/user-login.model";
import { useAuthStore } from "../store/auth.store";

const elements: FormItem[] = [
  {
    title: "Email",
    contentKey: "email",
    type: FormType.text,
  },
  {
    title: "Password",
    contentKey: "password",
    type: FormType.password,
  },
];

export default function LoginPage() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const login = useAuthStore((state) => state.login);

  const handleSubmit = (values: UserLoginEntity) => {
    loginApi(UserLoginModel.fromEntity(values))
      .then((res) => {
        setErrorMessage(null);
        login(res.data.token);
        navigate("/");
      })
      .catch((e) => {
        setErrorMessage(e.response.data.message);
      });
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-20 w-auto"
            src="https://vidavu-assests-stage.s3.amazonaws.com/assets/logo_image.png"
            alt="Your Company"
          />
          <h2 className="mt-4 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Vidavu Admin
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          {errorMessage && (
            <div className="py-2">
              <ErrorMessageComponent message={errorMessage} />
            </div>
          )}
          <PageFormComponent
            elements={elements}
            content={UserLoginEntity.createEmpty()}
            onClickSubmit={handleSubmit}
            submitButtonTitle="Sign in"
          />

          <p className="mt-10 text-center text-sm text-gray-500">
            Not a member?{" "}
            <Link
              to={PATHS.login}
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Please contact Vidavu administrator
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}
