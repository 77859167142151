import { AdminDetailsEntity } from "../entities/admin-details.entity";

export interface IAdminDetailsModel {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  createdAt: string;
}

export class AdminDetailsModel {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  createdAt: string;

  constructor(data: IAdminDetailsModel) {
    this.id = data.id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.createdAt = data.createdAt;
  }

  toEntity(): AdminDetailsEntity {
    return new AdminDetailsEntity({
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      createdAt: this.createdAt,
    });
  }
}
