import { StatisticsEntity } from "../entities/statistics.entity";

interface Data {
  users: {
    numUsers: number;
  };
  pages: {
    numUserPages: number;
    numPastPages: number;
    numFuturePages: number;
    numBirthPages: number;
    numEndPages: number;
  };
  devices: {
    desktopUsers: number;
    mobileUsers: number;
    tabletUsers: number;
  };
  sessions: {
    numSessions: number;
  };
}

export class StatisticsModel {
  users: {
    numUsers: number;
  };
  pages: {
    numUserPages: number;
    numPastPages: number;
    numFuturePages: number;
    numBirthPages: number;
    numEndPages: number;
  };
  devices: {
    desktopUsers: number;
    mobileUsers: number;
    tabletUsers: number;
  };
  sessions: {
    numSessions: number;
  };

  constructor(data: Data) {
    this.users = data.users;
    this.pages = data.pages;
    this.devices = data.devices;
    this.sessions = data.sessions;
  }

  toEntity = (): StatisticsEntity => ({
    activeUsers: this.users.numUsers,
    sessions: this.sessions.numSessions,
    userPages: this.pages.numUserPages,
    growth12Months: 0,
    avgSessionTime: 0,
    avgUserPages: 0,
    pastPages: this.pages.numPastPages,
    quizards: 0,
    coversAndFrames: 0,
    sponsors: 0,
    storeProducts: 0,
    premiumUsers: 0,
  });
}
