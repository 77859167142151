import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import DialogLayout from "../../../components/dialog-layout";
import { UserDetailsEntity } from "../entities/user-details.entity";

export default function DeleteUserDialog({
  open,
  setOpen,
  user,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  user: UserDetailsEntity;
}) {
  return (
    <DialogLayout
      title="Delete User"
      open={open}
      setOpen={setOpen}
      Icon={ExclamationTriangleIcon}
    >
      <div className="mt-2">
        <p className="text-sm text-gray-500">
          Are you sure you want to delete this user?
        </p>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
          onClick={() => setOpen(false)}
        >
          Delete
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={() => setOpen(false)}
        >
          Cancel
        </button>
      </div>
    </DialogLayout>
  );
}
